.App {
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fc-button{
  text-transform: capitalize !important;
  background-color: white !important;
  color: black !important;
  border-color: 1px solid #D9D9D9 !important;
  color: #000000d9 !important;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: flex !important;
}



.fc-daygrid-day-frame {
  position: relative;
  max-height: 221px !important;
  max-width: 175px !important;
  scroll-behavior: auto !important;
  overflow-y: auto !important;
}

.fc {
  height: 81vh !important;
}

.fc-day-today{
  background-color: #E6F7FF !important;
}

.site-calendar-demo-card {
  width: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.fc-button-active{
  z-index: 1;
  color: blue !important;
  background: #fff !important;
  border-color: blue !important;
}